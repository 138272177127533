import deltaE76 from "./deltaE76.js";
import deltaECMC from "./deltaECMC.js";
import deltaE2000 from "./deltaE2000.js";
import deltaEJz from "./deltaEJz.js";
import deltaEITP from "./deltaEITP.js";
import deltaEOK from "./deltaEOK.js";

export { deltaE76, deltaECMC, deltaE2000, deltaEJz, deltaEITP, deltaEOK };

export default {
	deltaE76,
	deltaECMC,
	deltaE2000,
	deltaEJz,
	deltaEITP,
	deltaEOK,
};
